<template>
  <div>
    <div class="kyc-card is-flex-direction-column">
      <p class="read-code-title has-text-weight-bold mb-0">
        {{
          isFailed
            ? $t("af:application.waiting_room.sign_failed.title")
            : $t("af:application.waiting_room.sign.title")
        }}
      </p>
      <template v-if="isData && !isDone">
        <!--        <p class="mb-0 has-text-centered">-->
        <!--          <qrcode-vue-->
        <!--            v-if="url"-->
        <!--            :value="url"-->
        <!--            :size="200"-->
        <!--            level="M"-->
        <!--            class="qrcode mb-2"-->
        <!--          />-->
        <!--        </p>-->
        <p class="is-size-6 has-text-grey mb-0">
          {{ $t("af:application.waiting_room.read_code.help_1") }}
        </p>
        <p class="is-size-6 has-text-grey mb-0">
          {{ $t("af:application.waiting_room.read_code.help_2") }}
        </p>
        <div class="is-flex is-justify-content-center">
          <a
            v-if="url && showReturnButton"
            :href="url"
            class="button is-text has-text-weight-bold"
            >{{
              $t("af:application.waiting_room.field.click_to_open_room.label")
            }}</a
          >
        </div>
      </template>
      <template v-else-if="!isDone">
        {{
          isFailed
            ? $t("af:flow_sign.failed.info")
            : $t("af:flow_sign.pending.info")
        }}
      </template>
    </div>

    <!--Statuses-->
    <template v-if="!isBrand(CUSTOM_APPEARANCE.MBH)">
      <div class="my-6" v-if="!isFailed">
        <div class="is-flex is-align-items-center mb-4">
          <b-icon
            icon-pack="fa"
            :icon="isStarted ? 'check-circle' : 'hourglass-half'"
            :type="isStarted ? 'is-green' : 'is-warning'"
            class="mr-2"
          ></b-icon>

          <span
            class="has-text-weight-medium"
            v-bind:class="[isStarted ? 'has-text-green' : 'hast-text-warning']"
            >{{ $t("af:application.waiting_room.label.started") }}</span
          >
        </div>
        <div v-if="isRetry" class="is-flex is-align-items-center mb-4">
          <b-icon
            icon-pack="fa"
            icon="exclamation-triangle"
            type="is-warning"
            class="mr-2"
          ></b-icon>

          <span class="has-text-weight-medium has-text-warning">{{
            $t("af:application.waiting_room.label.retry")
          }}</span>
        </div>
        <div class="is-flex is-align-items-center mb-4">
          <b-icon
            icon-pack="fa"
            :icon="isDone ? 'check-circle' : 'hourglass-half'"
            :type="isDone ? 'is-green' : 'is-warning'"
            class="mr-2"
          ></b-icon>

          <span
            class="has-text-weight-medium"
            v-bind:class="[isDone ? 'has-text-green' : 'hast-text-warning']"
            >{{ $t("af:application.waiting_room.label.done") }}</span
          >
        </div>
        <div class="is-flex is-align-items-center mb-4">
          <b-icon
            icon-pack="fa"
            :icon="isReviewed ? 'check-circle' : 'hourglass-half'"
            :type="isReviewed ? 'is-green' : 'is-warning'"
            class="mr-2"
          ></b-icon>

          <span
            class="has-text-weight-medium"
            v-bind:class="[isReviewed ? 'has-text-green' : 'hast-text-warning']"
            >{{ $t("af:application.waiting_room.label.review_success") }}</span
          >
        </div>
        <div class="is-flex is-align-items-center mb-4">
          <b-icon
            icon-pack="fa"
            :icon="isReviewed ? 'check-circle' : 'hourglass-half'"
            :type="isReviewed ? 'is-green' : 'is-warning'"
            class="mr-2"
          ></b-icon>

          <span
            class="has-text-weight-medium"
            v-bind:class="[isReviewed ? 'has-text-green' : 'hast-text-warning']"
            >{{
              $t("af:application.waiting_room.label.review_in_progress")
            }}</span
          >
        </div>
        <br />
        <br />
        <b-button
          id="waitingRoomButtonNext"
          v-if="isReviewed"
          class="next-btn"
          type="is-blue"
          @click="gotoNext"
          >{{ $t("af:application.waiting_room.button.next") }}</b-button
        >
      </div>
      <div class="my-6" v-if="isFailed">
        <div class="is-flex is-align-items-center mb-4">
          <b-icon
            icon-pack="fa"
            icon="skull-crossbones"
            type="is-danger"
            class="mr-2"
          ></b-icon>

          <span class="has-text-weight-medium has-text-danger">{{
            $t("af:application.waiting_room.label.failed")
          }}</span>
        </div>
      </div>
    </template>

    <template v-else>
      <div v-if="!isFailed">
        <div class="identification-step">
          <div class="identification-step__number" :class="{ done: isStarted }">
            <b-icon v-if="isStarted" icon="check" />
            <template v-else>1</template>
          </div>
          <div class="has-text-weight-bold">
            {{ $t("af:application.waiting_room.label.started") }}
          </div>
        </div>

        <div class="identification-step">
          <div class="identification-step__number" :class="{ done: isDone }">
            <b-icon v-if="isDone" icon="check" />
            <template v-else>2</template>
          </div>
          <div class="has-text-weight-bold">
            {{ $t("af:application.waiting_room.label.done") }}
          </div>
        </div>

        <!--        <div class="identification-step">-->
        <!--          <div-->
        <!--            class="identification-step__number"-->
        <!--            :class="{ done: isReviewed }"-->
        <!--          >-->
        <!--            <b-icon v-if="isReviewed" icon="check" />-->
        <!--            <template v-else>3</template>-->
        <!--          </div>-->
        <!--          <div class="has-text-weight-bold">-->
        <!--            {{ $t("af:application.waiting_room.label.review_success") }}-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <div class="identification-step">-->
        <!--          <div-->
        <!--            class="identification-step__number"-->
        <!--            :class="{ done: isReviewed }"-->
        <!--          >-->
        <!--            <b-icon v-if="isReviewed" icon="check" />-->
        <!--            <template v-else>4</template>-->
        <!--          </div>-->
        <!--          <div class="has-text-weight-bold">-->
        <!--            {{ $t("af:application.waiting_room.label.review_in_progress") }}-->
        <!--          </div>-->
        <!--        </div>-->

        <b-button
          id="waitingRoomButtonNext"
          v-if="isReviewed"
          class="next-btn mt-4"
          type="is-blue"
          @click="gotoNext"
          >{{ $t("af:application.waiting_room.button.next") }}</b-button
        >
      </div>
      <div v-if="isFailed">
        <div class="identification-step">
          <div class="has-text-weight-bold ml-4">
            {{ $t("af:application.waiting_room.label.failed") }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import QrcodeVue from "qrcode.vue";
import { mapActions, mapGetters } from "vuex";
import { CUSTOM_APPEARANCE } from "@/utils/const";
import { isBrand } from "@/utils/util";

export default {
  name: "ApplicationFlowWaitingRoom",
  components: {
    // QrcodeVue,
  },
  props: {
    position: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showReturnButton: false,
      poll: null,
    };
  },
  computed: {
    CUSTOM_APPEARANCE() {
      return CUSTOM_APPEARANCE;
    },
    isStarted() {
      return (
        this.status === "DOC_SIGN_STARTED" ||
        this.status === "DOC_SIGN_PENDING" ||
        this.status === "DOC_SIGN_REVIEWING" ||
        this.status === "DOC_SIGN_COMPLETED"
      );
    },
    isDone() {
      const done =
        this.status === "DOC_SIGN_REVIEWING" ||
        this.status === "DOC_SIGN_COMPLETED";
      if (done) {
        this.gotoNext();
      }
      return done;
    },
    isReviewed() {
      return (
        this.status === "DOC_SIGN_COMPLETED" ||
        this.status === "CONTRACT_SIGNED"
      );
    },
    isRetry() {
      return this.result === "RETRY";
    },
    isFailed() {
      return this.result === "FAILURE";
    },
    isMortgage() {
      return !!this.$route.meta.mortgage;
    },
    mobileSign() {
      return this.isMortgage ? this.mortgageMobileSign : this.loanMobileSign;
    },
    url() {
      return this.isMortgage ? this.mortgageUrl : this.loanUrl;
    },
    status() {
      return this.isMortgage ? this.mortgageStatus : this.loanStatus;
    },
    result() {
      return this.isMortgage ? this.mortgageResult : this.loanResult;
    },
    isData() {
      return this.isMortgage ? this.mortgageIsData : this.loanIsData;
    },
    ...mapGetters({
      loanMobileSign: "loanApplication/isKycMobile",
      loanUrl: "loanApplication/kycUrl",
      loanStatus: "loanApplication/applicationStatus",
      loanResult: "loanApplication/applicationStatusResult",
      loanIsData: "loanApplication/isSigningDataReceived",
      mortgageMobileSign: "mortgageApplication/isKycMobile",
      mortgageUrl: "mortgageApplication/kycUrl",
      mortgageStatus: "mortgageApplication/applicationStatus",
      mortgageResult: "mortgageApplication/applicationStatusResult",
      mortgageIsData: "mortgageApplication/isSigningDataReceived",
    }),
  },
  methods: {
    isBrand,
    ...mapActions({
      saveLoanPosition: "loanApplication/savePosition",
      saveMortgagePosition: "mortgageApplication/savePosition",
    }),
    gotoNext() {
      // if (this.isMortgage) {
      //   this.saveMortgagePosition({
      //     flowPosition: FLOW_POSITION.UNDER_REVIEW,
      //   });
      // } else {
      //   this.saveLoanPosition({
      //     flowPosition: FLOW_POSITION.UNDER_REVIEW,
      //   });
      // }
    },
    track(eventName) {
      this.$gtag.event(eventName);
    },
  },
  updated() {
    if (
      this.status === "DOC_SIGN_REVIEWING" ||
      this.status === "DOC_SIGN_COMPLETED"
    ) {
      this.track("signature_done");
    }
  },
  mounted() {
    if (!this.isDone) {
      this.poll = window.setInterval(() => {
        this.$store.dispatch("loanApplication/pollStatus");
      }, 3000);

      window.setTimeout(() => {
        this.showReturnButton = true;
      }, 6000);
    }
  },
  destroyed() {
    window.clearInterval(this.poll);
  },
};
</script>

<style scoped lang="scss">
.af-tile {
  margin: 1rem 0;
  background: #f3f5f9;
  border-radius: 8px;
  text-align: center;
  padding: 24px;
}

.required-documents {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;

  .required-document-tile {
    background: #f3f5f9;
    border-radius: 8px;
    text-align: center;
    padding: 10px 0;
  }
}

.qrcode {
  display: none;
}

.kyc-card {
  background: white;
  border-radius: 32px;
  padding: 24px;
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.read-code-title {
  font-size: 24px;
}

.identification-step {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1.5rem 0;
}

.mbh {
  .identification-step__number {
    border-radius: 0;
    background-image: url("../../assets/images/mbh_forma.svg");
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    font-weight: 700;
    color: #203667;
    font-size: 20px;
    margin-right: 8px;

    &.done {
      background-image: url("../../assets/images/mbh_forma_dark.svg");
      color: white;
    }
  }
}

@media only screen and (min-width: 769px) {
  .required-documents {
    grid-template-columns: repeat(2, 1fr);
  }

  .qrcode {
    display: block;
  }

  .kyc-card {
    padding: 32px;
    gap: 32px;
  }
}
</style>
